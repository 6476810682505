.details {
  &__grid{
    display: grid;
    grid-template-columns: repeat(8,1fr);
    gap: 1rem;
    grid-template-areas:
    "projects projects comercial comercial comercial music music music";
  }
}

.gallery {
  &__cont {
    border-radius: 25px;
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
  }

  &__card {
    border-radius: 25px;
    height: 48rem;
  }
}

.prev, .next {
  padding: 2.5rem 1.5rem 2.5rem 1rem;
  margin: 0 1rem;
  background: linear-gradient(90deg, $text, transparent);
  border-left: 1px solid $text;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

.__pn-handle-arrow {
  border-top: 25px solid transparent;
  border-right: 16px solid $black;
  border-bottom: 25px solid transparent;
  cursor: pointer;
}

.popup {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(222, 235, 233, 0.9);
  z-index: 2;
}