.nav{
  &__cont{
    padding: 2rem;
    background: white;
    border-radius: 25px;
    margin: 2rem 0 1rem;
  }
  &__btn{
    position: relative;
    width: 25%;
    border: 1px solid #dee2e5;
    border-radius: 20px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: $text;
  }
}
.career{
  &__cards{
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  &__card{
    background: white;
    padding: 1rem 2rem;
    border-radius: 25px;
  }
}
.apps{
  &__cont{
    margin-top: 2rem;
    padding: 2rem;
    background: white;
    border-radius: 25px;
  }
  &__img{
    width: 96px;
    height: 96px;
  }
}