.variant {
  background: black;
  color: white;
  border-radius: 25px;
  padding: 5px 15px;
  transition: .5s linear;
  &:hover{
    background: white;
    color: black;
    border: 1px solid black;
  }
}
.about {
  &__main-img{
    margin-top: 2rem;
    background: black;
  }
  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
  "nominations nominations"
  "numbers options";
    gap: 1rem;

    &-small {
      display: grid;
      grid-template-rows: auto auto;
      grid-template-columns: 1fr 1fr;
      grid-auto-columns: 1fr;
      width: 100%;
      height: 100%;
      column-gap: 2rem;
      row-gap: 2rem;
    }
    &-cards{
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    }
  }
  &__team{
    margin: 16px 0;
    padding: 2rem 1.5rem;
    background: white;
    border-radius: 25px;
  }
  &__text-cont{
    height: 100%;
    padding: 2rem;
    position: absolute;
  }

  &__card {
    border-radius: 25px;
    background: white;
    padding: 2rem 1.5rem;
    max-height: max-content;
  }
  &__social{
    justify-content: center;
    margin-top: 2rem;
  }
}
.numbers-content{
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  justify-self: start;
  text-transform: uppercase;
  cursor: default;
}