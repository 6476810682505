.contacts {
  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    grid-template-areas:
  "text text text photo"
  "button button button button"
  "photo1 text1 text1 text1";
  }

  &__text, .contacts__photo {
    background: white;
    padding: 2.5rem 2rem;
    border-radius: 25px;
  }

  &__text {

  }

  &__photo {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__logo{
    position: absolute;
    width: 200px;
    height: 200px;
    background-size: cover;
    border-radius: 50%;
  }
}
.contact:hover{
  cursor: pointer;
  & p{
    cursor: pointer;
    color: $text;
  }
  & .btn-bg{
    width: 100%;
    height: 100%;
  }
}

.round{
  &__text{
    position: absolute;
    width: 100%;
    height: 100%;
    animation: rotateText 10s linear infinite;
    &-cont {
      position: relative;
      width: 260px;
      height: 260px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__span{
    position: absolute;
    left: 50%;
    transform-origin: 0 130px;
  }
}
@keyframes rotateText {
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}