.submit{
  padding: .75rem;
  font-size: 1rem;
  border: 1px solid #9ba1a0;
  border-radius: 25px;
  background: #070707;
  color: white;
  cursor: pointer;
  transition: 0.3s linear;
  margin-top: 3rem;
  &:hover{
    background: #ebf1f5;
    color: #9ba1a0;
  }
}
.form{
  &__cont{
    padding: 4rem;
    background: white;
    border-radius: 25px;
    margin-top: 2rem;
  }
  &__social{
    justify-content: center;
    margin-bottom: 4rem;
  }
}

.input{
  &__label{
    width: 100%;
    position: relative;
    margin-bottom: 2rem;
  }
  &__err{
    color: red;
    position: absolute;
    bottom: -1rem;
    left: 1.5rem;
    font-size: 12px;
  }
  &__input{
    border-radius: 25px;
    padding: 1rem 1.5rem;
    width: 100%;
    border: 1px solid $border;
    font-size: 16px;
    color: $black;
    &.message{
      width: 100%;
      height: 8rem;
      resize: none;
    }
  }
}
.popup__message{
  position: fixed;
  display: flex;
  transform: translate(-50%,0);
  top: -5rem;
  left: 50%;
  background: white;
  padding: 20px;
  border: 1px solid green;
  border-radius: 25px;
  align-items: center;
  z-index: 2;
  opacity: 0;
  transition: 1s ease-in-out;
  &.active{
    opacity: 1;
    top: 1rem;
  }
}