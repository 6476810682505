.home {
  &__grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-areas:
  "comercial comercial comercial comercial music music music music"
  "service service service projects projects projects about about"
  "beauty beauty beauty beauty contacts contacts contacts contacts";
    gap: 1rem;
  }

  &__card {
    background: white no-repeat;
    background-size: cover;
    height: 20rem;
    padding: 2rem 1.5rem;
    border-radius: 25px;
    justify-content: flex-end;
    cursor: pointer;

    &-title {
      height: 100%;
    }
  }
  &__social{
    bottom: 2rem;
    left: 1.5rem;
  }
}