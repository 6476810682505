$black: #070707;
$text: #9ba1a0;
$BG: #ebf1f5;
$border: #dee2e5;
$hover: rgba(222, 226, 229, 0.3);
$active: rgba(222, 226, 229, 0.7);

* {
  box-sizing: border-box;
  margin: 0;
}

body {
  font-family: Inter, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $BG;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.container-80 {
  width: 100%;
  max-width: 80rem;
  margin: 0 auto;
}

.container-64 {
  width: 100%;
  max-width: 64rem;
  margin: 0 auto;
}

.container-48 {
  width: 100%;
  max-width: 48rem;
  margin: 0 auto;
}

.flex {
  display: flex;
}

.col {
  flex-direction: column;
}

.center {
  align-items: center;
}

.base {
  align-items: baseline;
}

.end {
  align-items: flex-end;
}

.text-start {
  text-align: start !important;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.space-b {
  justify-content: space-between;
}

.space-a {
  justify-content: space-around;
}

.mt-16 {
  margin-top: 16px;
}

.mt-32 {
  margin-top: 32px;
}

.mt-52 {
  margin-top: 52px;
}

.mt-112 {
  margin-top: 112px;
}

.mr-16 {
  margin-right: 16px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mb-52 {
  margin-bottom: 52px;
}

.m-52 {
  margin: 52px 0 !important;
}

.w-100 {
  width: 100%;
}

.col-gap {
  column-gap: 1rem;
}

//-------------------------------------- TEXT

.text-center {
  text-align: center;
  cursor: default;
}

.text-end {
  text-align: right;
  cursor: default;
}

a {
  text-decoration: none;
}

a.active {
  background: $active;
  color: $black;
  border-color: $black;
}

.h1 {
  font-size: 64px;
  color: $black;
  margin: 0;
  text-align: center;
  font-weight: 500;
  cursor: default;
}

.h2 {
  font-size: 60px;
  font-weight: 500;
  cursor: default;
}

.h3 {
  font-size: 40px;
  font-weight: 500;
  cursor: default;
}

.fs-14 {
  font-size: 14px;
}

.fs-20 {
  font-size: 20px;
}

.fs-56 {
  font-size: 56px;
  font-weight: 500;
}

.regular {
  font-size: 16px;
  cursor: default;
}

.small {
  font-size: 12px;
  cursor: default;
}

//-------------------------------------- TEXT

//-------------------------------------- COLOR

.gray {
  color: $text;
}

.bg {
  background: $BG;
}

.black {
  color: $black;
}

.light {
  background: $hover;
}

.dark {
  background: $black;
}

.white {
  color: white;
}

.white-bg {
  background: white !important;
}

//-------------------------------------- COLOR
.logo {
  text-align: center;
  padding: 20px 0;

  &-mini {
    width: 100%;
    cursor: default;
  }

  &-img {
    cursor: pointer;
  }
}

.ico-img {
  width: max-content;
}

.img {
  width: 100%;
  height: 100%;

  &-cont {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.article {
  padding: 0 2.5rem;
}

.box {
  background: $BG;
  border: 1px solid $border;
  border-radius: 25px;
  padding: 2px 5px;
  max-width: max-content;
  width: 100%;
  height: max-content;
}

.dot {
  min-width: 4px;
  min-height: 4px;
  width: 4px;
  height: 4px;
  background: $black;
  border-radius: 5px;
  margin-right: 3px;
}

.round {
  height: 12px;
  min-width: 12px;
  border: 3px solid $black;
  border-radius: 50%;
  margin-right: 6px;
}

.mark {
  width: 19px;
  height: 19px;
  margin-right: 1rem;
}

.long-btn {
  background: white;
  border-radius: 25px;
  padding: 2rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  & > .btn{
    margin-left: 0;
  }
}

.btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 64px;
  margin-left: 16px;
  width: 64px;
  height: 40px;
  border: 1px solid $border;
  border-radius: 25px;

  &-img {
    width: 14px;
    height: 20px;
    position: absolute;
    z-index: 1;
  }

  &-bg {
    position: absolute;
    transition: 0.2s linear;
    width: 0;
    height: 0;
    border-radius: 20px;
    background: $hover;
  }
}

.hover__card .h3, .hover__card .small {
  cursor: pointer;
}

.hover__card:hover .title {
  color: $text;
}

.nav__btn:hover {
  border-color: $black;

  & .btn {
    &-bg {
      width: 100%;
      height: 100%;
    }
  }
}

.link-btn:hover .btn, .hover__card:hover .btn {
  &-bg {
    width: 100%;
    height: 100%;
  }

  &-img {
    animation: .5s cubic-bezier(0.65, 0.05, 0.36, 1) move;
  }
}

.footer .social__cont {
  border: 1px solid $black;
}

.social {
  &__cont {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: 1px solid $border;
    border-radius: 50%;
    z-index: 1;

    &:not(:last-child) {
      margin-right: 1rem;
    }

    & .dark ~ .social__img {
      fill: $black;
    }

    &:hover {
      & > .dark ~ .social__img {
        fill: white;
      }

      & > .social__bg {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__bg {
    transition: linear .2s;
    width: 0;
    height: 0;
    border-radius: 50%;

    & .dark {
      background: $black;
    }
  }

  &__img {
    position: absolute;
    width: 14px;
    z-index: 1;
  }
}

.g_kroot {
  &:hover {
    & .h3 {
      color: white;
    }
  }

  & .home__card {
    background: #B9C0C5;
  }

  & .gray {
    color: white;
  }

  & .dot {
    background: white !important;
  }

  & .small {
    color: white !important;
  }

  & .btn {
    border-color: $black;
    background: white;
  }
}


@keyframes move {
  10% {
    right: 0;
  }
  20% {
    opacity: 0;
  }
  50% {
    left: 0;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
