.carousel {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  &__dots{
    justify-content: center;
    bottom: 1rem;
  }
  &__dot{
    z-index: 3;
    width: 1vw;
    height: 1vw;
    border: 1px solid white;
    background: none;
    border-radius: 50%;
    transition: .3s linear;
    &:not(:last-child){
      margin-right: 2vw;
    }
  }
  .dot-active{
    background: white;
  }

  &__list {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    justify-content: center;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 12px;

    //&[data-pos="0"] {
    //  z-index: 2;
    //  width: 75%;
    //}

    //&[data-pos="-1"],
    //&[data-pos="1"] {
    //  opacity: 0.7;
    //  filter: blur(1px) grayscale(10%);
    //  position: absolute;
    //  height: 50%;
    //  width: 20%;
    //  z-index: 1;
    //}
    //
    //&[data-pos="-1"] {
    //  transform: translate(0, -50%);
    //  left: 0;
    //  top: 50%;
    //}
    //
    //&[data-pos="1"] {
    //  transform: translate(0, -50%);
    //  right: 0;
    //  top: 50%;
    //}
  }
}