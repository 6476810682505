.filter {
  &__grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 3%;
    text-align: center;
  }
  &__flex{
    display: flex;
    align-items: center;
    gap: 1rem;
    z-index: 5;
    background: #ebf1f5;
    width: 100%;
    top: 2.75rem;
    padding-bottom: 3rem;
  }

  &__btn {
    position: relative;
    width: 100%;
    border: 1px solid $border;
    border-radius: 20px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;
    &-toggle{
      margin: 1rem auto 0;
    }
    &-active{
      border-color: $black;
      background: rgba(222, 226, 229, 0.7);
      color: $black;
    }
    &:hover .btn-bg{
      width: 100%;
      height: 100%;
      background: rgba(222, 226, 229, 0.7);
    }
  }

  &__text {
    width: max-content;
  }

  &__bg {
    z-index: -1;
  }
  &__img{
    transition: .2s linear;
    width: 16px;
    height: 16px;
    margin-left: 8px;
  }
}

.projects {
  &__grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }

  //&__text {
  //  padding: 2px 5px;
  //  border: 1px solid $border;
  //  border-radius: 25px;
  //  background: $BG;
  //}

  &__btn {
    width: 100%;
    padding: 0 2rem 2rem;
    border-radius: 25px;
  }
  &__card{
    min-height: 16rem;
  }
}

.project {
  &-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1.5rem;
  }
}

.rotate{
  transform: rotate(90deg);
}