$black: #070707;

.footer{
  padding: 4rem 2.5rem;
  max-width: 85rem;
  margin: 0 auto;
  & .social{
    width: 100%;
    justify-content: flex-end;
  }
}
.nav {
  padding-top: 4px;
  width: 100%;
  &__link .small{
    cursor: pointer;
  }
  &__link:not(:last-child){
    margin-right: 2rem;
  }

  &__dot {
    opacity: 0;
    transition: .3s linear;
  }
  &__link:hover {
    transform: translate(0, -0.25rem);
    color: $black !important;
    transition: .2s linear;

    & > .nav__dot {
      opacity: 1;
    }
  }
}